import React from 'react'
import Navbar from '../../../components/Navbar'
import BackToTop from '../../../components/BackToTop'
import { Container, NavbarGroup, Content } from '../style'

export default function General_v2() {
  return (
    <Container>
      <Container id="back-to-top-anchor" />

      <Navbar />

      <NavbarGroup aria-label="Conteúdo da página">
        <p>Índice</p>

        <ul>
          <li>
            <a href="#1">
              <span><strong>1.</strong> Imersões internacionais regulares e/ou segmentadas</span>
            </a>
          </li>

          <li>
            <a href="#2">
              <span><strong>2.</strong> Imersões internacionais exclusivas e corporativas</span>
            </a>
          </li>

          <li>
            <a href="#3">
              <span><strong>3.</strong> Turmas melhor escolha</span>
            </a>
          </li>

          <li>
            <a href="#4">
              <span><strong>4.</strong> Cursos presenciais, cursos online transmitidos ao vivo, eventos e workshops;</span>
            </a>
          </li>

          <li>
            <a href="#5">
              <span><strong>5.</strong> Cursos online</span>
            </a>
          </li>

          <li>
            <a href="#6">
              <span><strong>6.</strong> Contratos de Patrocínio</span>
            </a>
          </li>

          <li>
            <a href="#7">
              <span><strong>7.</strong> Disposições gerais</span>
            </a>
          </li>
        </ul>
      </NavbarGroup>

      <Content>
        <h1>Políticas Gerais de Cancelamento e Remarcação V2 – Compras a partir 15/05/2023</h1>

        <p>A presente política tem por objetivo informar sobre as regras estabelecidas para as matrículas,
          reagendamentos, cancelamentos e reembolsos relacionados a todos os Eventos Presenciais (tais como Cursos
          Presenciais, Workshops, Eventos, Programas de Imersões Executivas Internacionais (“Eventos Presenciais”), bem
          como a todos os Cursos Online (“Cursos Online”), cujas inscrições se dão no site
          da <strong>STARTSE</strong> ou através de seus canais de atendimento e vendas, com amparo no artigo 49 da Lei
          nº 8.078/1990 (“Política”).</p>
        <p>O Participante que se inscrever, e/ou o Contratante que inscrever seus colaboradores ou convidados, nos
          Eventos Presenciais e/ou Cursos Online da <strong>STARTSE</strong>, atesta que leu integralmente e está de
          acordo com a presente Política, com os termos de uso e política de privacidade referentes à mesma.</p>

        <p id="1"><strong>1. IMERSÕES INTERNACIONAIS REGULARES E/OU SEGMENTADAS:</strong></p>
        <p>1.1. Este capítulo se refere aos Programas de Imersões Executivas Internacionais, cuja compra é feita de
          maneira individual e realizada em grupos abertos, podendo ser segmentado ou não, tais como: “Missão Vale do
          Silício, Missão Vale do Silício Agro, Missão China, Missão China Varejo” e similares, por exemplo.</p>

        <p>1.1.1. <u>Da Matrícula:</u> A vaga do Participante somente será reservada e concretizada após a devida
          comprovação de pagamento pela instituição financeira competente.</p>
        <p>1.1.2. <u>Da Troca de Participantes:</u> A troca de Participante deverá ser realizada diretamente na
          plataforma da <strong>STARTSE</strong> (visualizar compra > participantes > alterar), com até 30 (trinta) dias
          de antecedência da realização do Programa de Imersão Internacional.</p>
        <p>1.1.2.1. Caso não haja tal comunicação para alteração, o substituto do Participante não poderá fazer parte do
          Programa de Imersão Executiva Internacional e, ainda, poderá prejudicar a experiência do restante do grupo,
          por conta de motivos operacionais.</p>
        <p>1.1.3. Do Reagendamento, Cancelamento e Reembolso:</p>
        <p>1.1.3.1. <u>Por parte do Participante:</u></p>
        <p>a) Reagendamento:</p>
        <p>i. O Participante poderá solicitar o reagendamento de sua inscrição em até 30 (trinta) dias antes da
          realização do Programa de Imersão Executiva Internacional adquirido, desde que o mesmo seja aproveitado em
          outra data e que o novo Programa de Imersão Executiva Internacional selecionada seja de igual valor, sendo que
          esta ação está limitada a 2 (dois) reagendamentos no prazo de até 1 (um) ano;</p>
        <p>ii. O Participante poderá solicitar o reagendamento de sua inscrição faltando menos de 30 (trinta) dias para
          a data de início, estando sujeito à cobrança de uma taxa de 40% (quarenta por cento) sobre o valor vigente do
          programa em questão. A presente previsão se aplica, ainda, em casos de Participantes que possuírem cortesia no
          acesso aos programas;</p>
        <p>iii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa
          pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas
          com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto,
          despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por
          ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade
          da <strong>STARTSE</strong></p>
        <p>iv. Caso o Participante opte por um Programa de Imersão Executiva Internacional de maior valor que o crédito
          decorrente do reagendamento em questão, o Participante deverá realizar o pagamento da diferença para efetivar
          a nova inscrição no Programa de Imersão Executiva Internacional desejado;</p>
        <p>v. O crédito será aplicado para Imersões Internacionais, não podendo ser aplicado para Cursos Presenciais,
          Eventos e/ou Workshops em território nacional; e </p>
        <p>vi. O Participante que optar por não utilizar imediatamente o crédito oriundo da alteração de Programa de
          Imersão Executiva Internacional e não escolher uma nova turma no ato da remarcação, deverá efetuar tal escolha
          em até 12 (doze) meses, prazo este definido para validade do crédito, após o qual será extinto, sendo certo
          que o Participante, ao escolher a turma desejada, estará sujeito ao custo vigente do referido Programa,
          devendo arcar com eventual diferença tarifária.</p>
        <p>b) Cancelamento e Reembolso:</p>
        <p>i. No prazo de até 7 (sete) dias corridos após o pagamento do Programa de Imersão Executiva Internacional, o
          cancelamento será confirmado e o reembolso ao Participante será integral;</p>
        <p>ii. A partir do 8º (oitavo) dia corrido após o pagamento do Programa de Imersão Executiva Internacional e em
          até 45 (quarenta e cinco) dias antes do início do referido Programa de Imersão Executiva Internacional, o
          cancelamento será confirmado e o reembolso será de 50% (cinquenta por cento) do valor total acrescido de
          impostos;</p>
        <p>iii. A partir do 8º (oitavo) dia corrido após o pagamento e em até 30 (trinta) dias antes do início do
          Programa de Imersão Executiva Internacional, o cancelamento será confirmado e o reembolso será de 30% (trinta
          por cento) do valor total acrescido de impostos;</p>
        <p>iv. A partir do 8º (oitavo) dia corrido após o pagamento e em até 29 (vinte e nove) dias antes do início do
          Programa de Imersão Executiva Internacional, o cancelamento será realizado, contudo, não haverá qualquer
          reembolso. Caso haja parcelas pendentes de pagamento, as mesmas deverão ser quitadas normalmente;</p>
        <p>v. A ausência ou atraso do Participante nos dias do Programa de Imersão Executiva Internacional, sob qualquer
          hipótese, não dará direito a bonificação, desconto ou transferência de inscrição;</p>
        <p>vi. Em casos de faturamento (confirmação com fatura antecipada) será considerada a data em que houver a
          emissão do respectivo documento;</p>
        <p>vii. Se a solicitação de cancelamento for aprovada pela <strong>STARTSE</strong>, o Participante receberá um
          e-mail contendo o valor a ser restituído e um formulário para ser preenchido. O reembolso ocorrerá no 20° dia
          do mês do envio do formulário, caso este seja enviado até o 10° dia. Se o envio do formulário ocorrer após o
          10° dia, o reembolso acontecerá no 20° dia do mês subsequente; e</p>
        <p>ix. Caso o Participante opte por converter o valor oriundo do cancelamento em crédito para uso na
          Plataforma <strong>STARTSE</strong>, observadas as condições acima indicadas, deverá utilizá-lo em até 12
          (doze) meses após sua confirmação pela <strong>STARTSE</strong>, prazo de validade para créditos, não sendo
          possível o seu reembolso.</p>
        <p>1.1.3.2. <u>Do Grupo Mínimo:</u></p>
        <p>i. Caso o número de Participantes, após eventuais cancelamentos, venha a ser inferior a 15 (quinze)
          participantes, a <strong>STARTSE</strong> reserva-se ao direito de não realizar o Programa de Imersão
          Executiva Internacional, sem incidência de qualquer multa à <strong>STARTSE</strong> neste sentido; e</p>
        <p>ii. Nesse caso, os Participantes remanescentes cumprirão a regra de cancelamento por parte do Participante,
          sendo que as regras de reembolso serão orientadas pela data de solicitação de cancelamento do 15º (décimo
          quinto) Participante remanescente do grupo exclusivo.</p>
        <p>1.1.3.3. <u>Por parte da <strong>STARTSE</strong>:</u></p>
        <p>a) Reagendamento e/ou Cancelamento:</p>
        <p>i. A <strong>STARTSE</strong> poderá reagendar ou cancelar um Programa de Imersão Executiva Internacional, de
          maneira imotivada, ou seja, a seu único exclusivo critério, da seguinte forma:</p>
        <p>a) Com aviso prévio de até 30 (trinta) dias antes do Programa de Imersão Executiva Internacional, o reembolso
          ao Participante será integral e a <strong>STARTSE</strong> não incorrerá em qualquer multa;</p>
        <p>b) Com aviso prévio a partir do 29º (vigésimo nono) dia antes do Programa de Imersão Executiva Internacional
          e em até 10 (dez) dias antes do Programa de Imersão Executiva Internacional, o reembolso ao Participante será
          integral e a <strong>STARTSE</strong> incorrerá em uma multa de 10% (dez por cento) do valor da Imersão
          Internacional reagendado ou cancelado, a ser paga ao Participante;</p>
        <p>c) Com aviso prévio a partir do 9º (nono) dia antes do Programa de Imersão Executiva Internacional e até o
          dia do Programa de Imersão Executiva Internacional, o reembolso ao Participante será integral e
          a <strong>STARTSE</strong> incorrerá em uma multa de 20% (vinte por cento) do valor do Programa de Imersão
          Executiva Internacional reagendado ou cancelado, a ser paga ao Participante.</p>
        <p>ii. Nas hipóteses do item (i) acima, caberá ao Participante optar pelo reembolso integral do valor pago pelo
          Programa de Imersão Executiva Internacional reagendado e/ou cancelado ou uma nova inscrição em outro Programa
          de Imersão Executiva Internacional de igual valor a ser realizado;</p>
        <p>iii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa
          pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas
          com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto,
          despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por
          ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade
          da <strong>STARTSE</strong>; e</p>
        <p>iv. Na hipótese de reagendamento e/ou cancelamento de Imersões Internacionais decorrentes de caso fortuito,
          motivados de força maior, causas extremas, ou qualquer outro fato alheio à <strong>STARTSE</strong>, a mesma
          não incorrerá em qualquer tipo de multa. Entretanto, nestes casos, a <strong>STARTSE</strong> possibilitará ao
          Participante optar pelo reembolso integral do valor pago pelo Programa de Imersão Executiva Internacional
          reagendada e/ou cancelada ou realizar uma nova inscrição em outro Programa de Imersão Executiva Internacional
          de igual valor a ser realizado.</p>

        <p id="2"><strong>2. IMERSÕES INTERNACIONAIS EXCLUSIVAS E CORPORATIVAS:</strong></p>
        <p>2.1. Este capítulo trata dos Programas de Imersões Executivas Internacionais compradas de maneira individual
          em grupos exclusivos, podendo ser segmentado ou não, bem como das adquiridas por pessoa jurídica
          (“Contratante”) para colaboradores e/ou convidados (ex: clientes e fornecedores);</p>
        <p>2.1.1. <u>Da Matrícula:</u> A vaga do Participante somente será reservada e concretizada após a devida
          comprovação de pagamento pela instituição financeira, e no caso das corporativas, somente será reservada e
          concretizada após a devida assinatura do contrato de prestação de serviço entre a <strong>STARTSE</strong> e a
          Contratante.</p>
        <p>2.1.1.1. No caso dos Participantes serem convidados da Contratante, os mesmos estarão submetidos ao contrato
          estabelecido e celebrado entre a Contratante e a <strong>STARTSE</strong>.</p>
        <p>2.1.2. <u>Da Troca de Participantes:</u> deverá ser alterada diretamente na plataforma (visualizar compra >
          participantes > alterar) com até A troca de Participante deverá ser realizada diretamente na plataforma
          da <strong>STARTSE</strong> (visualizar compra > participantes > alterar), com até 30 (trinta) dias de
          antecedência da realização do Programa de Imersão Internacional.</p>
        <p>2.1.2.1. Caso não haja tal comunicação para alteração, o substituto do Participante não poderá fazer parte do
          Programa de Imersão Executiva Internacional Programa de Imersão Executiva Internacional e, ainda, poderá
          prejudicar a experiência do restante do grupo, por conta de motivos operacionais.</p>
        <p>2.1.3. <u>Do Reagendamento, Cancelamento e Reembolso:</u></p>
        <p>2.1.3.1. <u>Reagendamento:</u></p>
        <p>i. O grupo, de forma unificada (100% dos participantes), poderá solicitar reagendamento da data do Programa
          de Imersão Executiva Internacional do grupo, sem multas, em até 60 dias antes da realização da mesma;</p>
        <p>ii. Após o período acima citado, serão aplicadas as regras de cancelamento (ver cláusula 2.1.3.3);</p>
        <p>iii. A nova data para o grupo exclusivo será definida em comum acordo com a <strong>STARTSE</strong>; e</p>
        <p>iv. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa
          pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas
          com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto,
          despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por
          ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade
          da <strong>STARTSE</strong>.</p>
        <p>2.1.3.2. <u>Reagendamento por Parte do Participante:</u></p>
        <p>i. Por tratar-se de um grupo exclusivo, o Participante não poderá solicitar reagendamento para outro Programa
          de Imersão Executiva Internacional e deverá solicitar o cancelamento de sua inscrição no grupo exclusivo,
          sabendo que poderá não cumprir a regra do grupo mínimo de 15 participantes (cláusula 2.1.3.4); e</p>
        <p>ii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa
          pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas
          com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto,
          despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por
          ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade
          da <strong>STARTSE</strong>.</p>
        <p>2.1.3.3. <u>Cancelamento por Parte do Participante ou Contratante:</u></p>
        <p>i. No prazo de até 7 (sete) dias corridos após o pagamento do Programa de Imersão Executiva Internacional, o
          cancelamento será confirmado e o reembolso ao Participante ou Contratante será integral;</p>
        <p>ii. A partir do 8º (oitavo) dia corrido após o pagamento do Programa de Imersão Executiva Internacional e em
          até 45 (quarenta e cinco) dias antes do início do mesmo, o cancelamento será confirmado e o reembolso será de
          50% (cinquenta por cento) do valor total acrescido de impostos;</p>
        <p>iii. A partir do 8º (oitavo) dia corrido após o pagamento e em até 30 (trinta) dias antes do início do
          Programa de Imersão Executiva Internacional, o cancelamento será confirmado e o reembolso será de 30% (trinta
          por cento) do valor total acrescido de impostos;</p>
        <p>iv. A partir do 8º (oitavo) dia corrido após o pagamento e em até 29 (vinte e nove) dias antes do início do
          Programa de Imersão Executiva Internacional, o cancelamento será realizado, contudo, não haverá qualquer
          reembolso. Caso haja parcelas pendentes de pagamento, as mesmas deverão ser quitadas normalmente;</p>
        <p>v. A ausência ou atraso do Participante nos dias do Programa de Imersão Executiva Internacional, sob qualquer
          hipótese, não dará direito a bonificação, desconto ou transferência de inscrição;</p>
        <p>vi. Em casos de faturamento (confirmação com fatura antecipada) será considerada a data em que houver a
          emissão do respectivo documento;</p>
        <p>vii. Se a solicitação de cancelamento for aprovado pela <strong>STARTSE</strong>, o Participante receberá um
          e-mail contendo o valor a ser restituído e um formulário para ser preenchido. O reembolso ocorrerá no 20° dia
          do mês do envio do formulário, caso este seja enviado até o 10° dia. Se o envio do formulário ocorrer após o
          10° dia, o reembolso acontecerá no 20° dia do mês subsequente;</p>
        <p>viii. Em até 30 (trinta) dias anteriores à data de início do Programa, a <strong>STARTSE</strong> permitirá
          ao Contratante/Participante isenção de multa contratual, desde que este concorde em converter o valor do seu
          respectivo reembolso em crédito na Plataforma <strong>STARTSE</strong>, podendo utilizá-lo para contratar
          outros produtos <strong>STARTSE</strong> da mesma modalidade; e</p>
        <p>ix. Caso o Contratante/Participante opte por converter o valor oriundo do cancelamento em crédito para uso na
          Plataforma <strong>STARTSE</strong>, observadas as condições acima indicadas, deverá utilizá-lo em até 12
          (doze) meses após sua confirmação pela <strong>STARTSE</strong>, prazo de validade para créditos, não sendo
          possível o seu reembolso.</p>
        <p>2.1.3.4. <u>Grupo Mínimo:</u></p>
        <p>i. Caso o número de Participantes do grupo exclusivo, após eventuais cancelamentos, venha a ser inferior a 15
          (quinze) participantes, a <strong>STARTSE</strong> reserva-se ao direito de não realizar o Programa de Imersão
          Executiva Internacional, sem incidência de qualquer multa à <strong>STARTSE</strong> neste sentido; e</p>
        <p>ii. Nesse caso, os Participantes remanescentes cumprirão a regra de cancelamento por parte do Participante,
          sendo que as regras de reembolso serão orientadas pela data de solicitação de cancelamento do 15º (décimo
          quinto) Participante remanescente do grupo exclusivo.</p>
        <p>2.1.3.5. <u>Reagendamento e/ou Cancelamento por parte da <strong>STARTSE</strong>:</u></p>
        <p>i. A <strong>STARTSE</strong> poderá reagendar ou cancelar um Programa de Imersão Executiva Internacional, de
          maneira imotivada, ou seja, a seu único exclusivo critério, da seguinte forma:</p>
        <p>a) Com aviso prévio de até 30 (trinta) dias antes do Programa de Imersão Executiva Internacional, o reembolso
          ao Participante será integral e a <strong>STARTSE</strong> não incorrerá em qualquer multa;</p>
        <p>b) Com aviso prévio a partir do 29º (vigésimo nono) dia antes do Programa de Imersão Executiva Internacional
          e em até 10 (dez) dias antes do Programa de Imersão Executiva Internacional, o reembolso ao Participante será
          integral e a <strong>STARTSE</strong> incorrerá em uma multa de 10% (dez por cento) do valor do Programa de
          Imersão Executiva Internacional reagendado ou cancelado, a ser paga ao Participante;</p>
        <p>c) Com aviso prévio a partir do 9º (nono) dia antes do Programa de Imersão Executiva Internacional e até o
          dia do Programa de Imersão Executiva Internacional, o reembolso ao Participante será integral e
          a <strong>STARTSE</strong> incorrerá em uma multa de 20% (vinte por cento) do valor do Programa de Imersão
          Executiva Internacional reagendado ou cancelado, a ser paga ao Participante.</p>
        <p>ii. Na hipótese do item (i) acima, caberá ao Participante optar pelo reembolso integral do valor pago pelo
          Programa de Imersão Executiva Internacional reagendada e/ou cancelada ou uma nova inscrição em outro Programa
          de Imersão Executiva Internacional de igual valor a ser realizada;</p>
        <p>iii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa
          pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas
          com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto,
          despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por
          ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade
          da <strong>STARTSE</strong>; e</p>
        <p>iv. Na hipótese de reagendamento e/ou cancelamento de Programas de Imersões Executivas Internacionais
          decorrentes de caso fortuito, motivados de força maior, causas extremas, ou qualquer outro fato alheio
          à <strong>STARTSE</strong>, a mesma não incorrerá em qualquer tipo de multa. Entretanto, nestes casos,
          a <strong>STARTSE</strong> possibilitará ao Participante optar pelo reembolso integral do valor pago pelo
          Programa de Imersão Executiva Internacional reagendada e/ou cancelada ou realizar uma nova inscrição em outro
          Programa de Imersão Executiva Internacional de igual valor a ser realizada.</p>

        <p id="3"><strong>3. TURMAS MELHOR ESCOLHA</strong></p>
        <p>3.1. Especificamente no que tange à aquisição de produtos / serviços sob a modalidade Melhor Escolha,
          aplicar-se-ão os seguintes termos e condições:</p>
        <p>3.1.1. Cursos e Missões Internacionais.</p>
        <p>3.1.1.1. Prazo para uso, reagendamentos e cancelamento.</p>
        <p>a) <u>Prazo para uso.</u> Efetuada a compra, o Participante deverá usufruir do Curso / Missão Internacional
          em até 12 (doze) meses, mediante a escolha da turma desejada, conforme disponibilidade informada
          pela <strong>STARTSE</strong>, sendo certo que a <strong>STARTSE</strong> garantirá, no mínimo, 2 (duas)
          turmas no período de 12 (doze) meses após a referida compra. Contudo, caso, por alguma eventualidade, tais
          turmas não sejam formadas, o prazo para uso será prorrogado até que se forme uma nova turma, que será a última
          opção ofertada ao Participante.</p>
        <p>b) <u>Reagendamento.</u> Efetuada a inscrição em turma disponibilizada pela <strong>STARTSE</strong>, caso o
          Participante deseje alterar para outra, esta alteração será autorizada por até 2 (duas) vezes, desde que
          solicitada pelo Participante com pelo menos 30 (trinta) dias antes da data de início da turma em que estiver
          matriculado, sendo certo que a validade de seu prazo para uso não será alterada, não sendo possível
          reagendamentos quando o Participante estiver matriculado na última opção de oferta disponibilizada no período
          de uso indicado no item “a” acima e não se aplicando as condições previstas no item “a” acima caso
          a <strong>STARTSE</strong> tenha cumprido a quantidade mínima de ofertas para o período de validade ali também
          indicado.</p>
        <p>c) <u>Cancelamento.</u> O Participante poderá solicitar o cancelamento de sua inscrição com reembolso
          integral em até 7 (sete) dias corridos após a sua compra, conforme Código de Defesa do Consumidor (direito de
          arrependimento). Transcorrido o prazo de 7 (sete) dias corridos, o Participante não terá direito a qualquer
          reembolso, parcial ou total, sendo devido o pagamento integral do preço à <strong>STARTSE</strong>.</p>
        <p>3.1.2. Cursos presenciais.</p>
        <p>3.1.2.1. Prazo para uso, reagendamentos e cancelamento.</p>
        <p>a) <u>Prazo para uso.</u> Efetuada a compra, o Participante deverá usufruir do Curso Presencial em até 12
          (doze) meses, mediante a escolha da turma desejada, conforme disponibilidade informada
          pela <strong>STARTSE</strong>, sendo certo que a <strong>STARTSE</strong> garantirá, no mínimo, 2 (duas)
          turmas no período de 12 (doze) meses após a referida compra. Contudo, caso, por alguma eventualidade, tais
          turmas não sejam formadas, o prazo para uso será prorrogado até que se forme uma nova turma, que será a última
          opção ofertada ao Participante.</p>
        <p>b) <u>Reagendamento.</u> Efetuada a inscrição em turma disponibilizada pela <strong>STARTSE</strong>, caso o
          Participante deseje alterar para outra, esta alteração será autorizada por até 2 (duas) vezes, desde que
          solicitada pelo Participante com pelo menos 30 (trinta) dias antes da data de início da turma em que estiver
          matriculado, sendo certo que a validade de seu prazo para uso não será alterada, não sendo possível
          reagendamentos quando o Participante estiver matriculado na última opção de oferta disponibilizada no período
          de uso indicado no item “a” acima e não se aplicando as condições previstas no item “a” acima caso
          a <strong>STARTSE</strong> tenha cumprido a quantidade mínima de ofertas para o período de validade ali também
          indicado.</p>
        <p>c) <u>Cancelamento.</u> O Participante poderá solicitar o cancelamento de sua inscrição com reembolso
          integral, até 7 (sete) dias corridos após a sua compra, conforme Código de Defesa do Consumidor (direito de
          arrependimento). Transcorrido o prazo de 7 (sete) dias corridos, o Participante não terá direito a qualquer
          reembolso, parcial ou total, sendo devido o pagamento integral do preço à <strong>STARTSE</strong>.</p>

        <p id="4"><strong>4. CURSOS PRESENCIAIS, CURSOS ONLINE TRANSMITIDOS AO VIVO, EVENTOS E WORKSHOP:</strong></p>
        <p>4.1. Este capítulo se refere aos Cursos Presenciais, Cursos Online Transmitidos ao Vivo, Eventos e
          Workshops.</p>
        <p>4.1.1. <u>Da Matrícula:</u> A vaga do Participante somente será reservada e concretizada após a devida
          comprovação de pagamento pela instituição financeira competente.</p>
        <p>4.1.2. <u>Da Troca de Participantes:</u> A troca de Participante deverá ser realizada diretamente na
          plataforma da <strong>STARTSE</strong> (visualizar compra > participantes > alterar), com até 30 (trinta) dias
          de antecedência da realização dos Cursos Presenciais, Cursos Online Transmitidos ao Vivo, Eventos e Workshops.
        </p>
        <p>4.1.2.1. Caso não haja tal comunicação para alteração, o substituto do Participante não poderá fazer parte
          dos Cursos Presenciais, Cursos Online Transmitidos ao Vivo, Eventos e Workshops.</p>
        <p>4.1.3. <u>Do Reagendamento, Cancelamento e Reembolso:</u></p>
        <p>4.1.3.1. <u>Por parte do Participante:</u></p>
        <p>a) Reagendamento:</p>
        <p>i. O Participante poderá solicitar o reagendamento de sua inscrição em até 30 (trinta) dias antes da
          realização Cursos Presenciais, Cursos Online Transmitidos ao Vivo, Eventos e Workshops adquiridos, desde que a
          mesma seja aproveitada em outra data e que o novo Curso Presencial, Curso Online Transmitido ao Vivo, Evento
          ou Workshop selecionado seja de igual valor, sendo que esta ação está limitada a 2 (dois) reagendamentos no
          prazo de até 1 (um) ano;</p>
        <p>ii. O Participante poderá solicitar o reagendamento de sua inscrição faltando menos de 30 (trinta) dias para
          a data de início, estando sujeito à cobrança de uma taxa de 40% (quarenta por cento) sobre o valor vigente do
          programa em questão. A presente previsão se aplica, ainda, em casos de Participantes que possuírem cortesia no
          acesso aos programas;</p>
        <p>iii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa
          pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas
          com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto,
          despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por
          ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade
          da <strong>STARTSE</strong>;</p>
        <p>iv. Caso o Participante opte por um Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop de
          maior valor que o crédito decorrente do reagendamento em questão, o Participante deverá realizar o pagamento
          da diferença para efetivar a nova inscrição no Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou
          Workshop desejado;</p>
        <p>v. O crédito será aplicado para Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, não
          podendo ser aplicado para Programas de Imersões Executivas Internacionais; e</p>
        <p>vi. O Participante que optar por não utilizar imediatamente o crédito oriundo de reagendamento e não escolher
          um novo Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop no ato da remarcação, deverá
          efetuar tal escolha em até 12 (doze) meses, prazo este definido para validade do crédito, após o qual será
          extinto.</p>
        <p>b) Cancelamento:</p>
        <p>i. No prazo de até 7 (sete) dias corridos após o pagamento do Curso Presencial, Curso Online Transmitido ao
          Vivo, Evento ou Workshop, o cancelamento será confirmado e o reembolso ao Participante será integral;</p>
        <p>ii. A partir do 8º (oitavo) dia corrido após o pagamento do Curso Presencial, Curso Online Transmitido ao
          Vivo, Evento ou Workshop e em até 45 (quarenta e cinco) dias antes do início do referido Curso Presencial,
          Curso Online Transmitido ao Vivo, Evento ou Workshop, o cancelamento será confirmado e o reembolso será de 50%
          (cinquenta por cento) do valor total acrescido de impostos;</p>
        <p>iii. A partir do 8º (oitavo) dia corrido após o pagamento e em até 30 (trinta) dias antes do início do Curso
          Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, o cancelamento será confirmado e o reembolso
          será de 30% (trinta por cento) do valor total acrescido de impostos;</p>
        <p>iv. A partir do 8º (oitavo) dia corrido após o pagamento e em até 29 (vinte e nove) dias antes do início da
          imersão, o cancelamento será realizado, contudo, não haverá qualquer reembolso. Caso haja parcelas pendentes
          de pagamento, as mesmas deverão ser quitadas normalmente;</p>
        <p>v. A ausência ou atraso do Participante nos dias do Curso Presencial, Curso Online Transmitido ao Vivo,
          Evento ou Workshop, sob qualquer hipótese, não dará direito a bonificação, desconto ou transferência de
          inscrição;</p>
        <p>vi. Em casos de faturamento (confirmação com fatura antecipada) será considerada a data em que houver a
          emissão do respectivo documento;</p>
        <p>vii. Se a solicitação de cancelamento for aprovada pela <strong>STARTSE</strong>, o Participante receberá um
          e-mail contendo o valor a ser restituído e um formulário para ser preenchido. O reembolso ocorrerá no 20° dia
          do mês do envio do formulário, caso este seja enviado até o 10° dia. Se o envio do formulário ocorrer após o
          10° dia, o reembolso acontecerá no 20° dia do mês subsequente;</p>
        <p>viii. Em até 30 (trinta) dias anteriores à data de início do Curso Presencial, Curso Online Transmitido ao
          Vivo, Evento ou Workshop, a <strong>STARTSE</strong> permitirá ao Contratante/Participante isenção de multa
          contratual, desde que este concorde em converter o valor do seu respectivo reembolso em crédito na
          Plataforma <strong>STARTSE</strong>, podendo utilizá-lo para contratar outros
          produtos <strong>STARTSE</strong> da mesma modalidade; e</p>
        <p>ix. Caso o Contratante/Participante opte por converter o valor oriundo do cancelamento em crédito para uso na
          Plataforma <strong>STARTSE</strong>, observadas as condições acima indicadas, deverá utilizá-lo em até 12
          (doze) meses após sua confirmação pela <strong>STARTSE</strong>, prazo de validade para créditos, não sendo
          possível o seu reembolso.</p>
        <p>4.1.3.2. <u>Por parte da <strong>STARTSE</strong>:</u></p>
        <p>a) Reagendamento e/ou Cancelamento:</p>
        <p>i. A <strong>STARTSE</strong> poderá reagendar ou cancelar um Curso Presencial, Curso Online Transmitido ao
          Vivo, Evento ou Workshop, de maneira imotivada, ou seja, a seu único exclusivo critério, da seguinte forma:
        </p>
        <p>a) Com aviso prévio de até 30 (trinta) dias antes do Curso Presencial, Curso Online Transmitido ao Vivo,
          Evento ou Workshop, o reembolso ao Participante será integral e a <strong>STARTSE</strong> não incorrerá em
          nenhuma multa;</p>
        <p>b) Com aviso prévio a partir do 29º (vigésimo nono) dia antes do Curso Presencial, Curso Online Transmitido
          ao Vivo, Evento ou Workshop e em até 10 (dez) dias antes do Curso Presencial, Curso Online Transmitido ao
          Vivo, Evento ou Workshop, o reembolso ao Participante será integral e a <strong>STARTSE</strong> incorrerá em
          uma multa de 10% (dez por cento) do valor do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou
          Workshop reagendado ou cancelado, a ser paga ao Participante;</p>
        <p>c) Com aviso prévio a partir do 9º (nono) dia antes do Curso Presencial, Curso Online Transmitido ao Vivo,
          Evento ou Workshop e até o dia do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop, o
          reembolso ao Participante será integral e a <strong>STARTSE</strong> incorrerá em uma multa de 20% (vinte por
          cento) do valor do Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop reagendado ou
          cancelado, a ser paga ao Participante.</p>
        <p>ii. Nas hipóteses do item (i) acima, caberá ao Participante optar pelo reembolso integral do valor pago pelo
          Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop reagendado e/ou cancelado ou uma nova
          inscrição em outro Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop de igual valor a ser
          realizado;</p>
        <p>iii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens aéreas, taxa
          pró-turismo, despesas com expedição de passaportes e obtenção de vistos consulares, custo de vacinas, taxas
          com expedição e carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de quarto,
          despesas decorrentes de diárias e deslocamentos, que serão de responsabilidade exclusiva do Participante e por
          ele diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a responsabilidade
          da <strong>STARTSE</strong>; e</p>
        <p>iv. Na hipótese de reagendamento e/ou cancelamento de Curso Presencial, Curso Online Transmitido ao Vivo,
          Evento ou Workshop decorrentes de caso fortuito, motivados de força maior, causas extremas, ou qualquer outro
          fato alheio à <strong>STARTSE</strong>, a mesma não incorrerá em qualquer tipo de multa. Entretanto, nestes
          casos, a <strong>STARTSE</strong> possibilitará ao Participante optar pelo reembolso integral do valor pago
          pelo Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop reagendada e/ou cancelada ou
          realizar uma nova inscrição em outro Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop de
          igual valor a ser realizada.</p>

        <p id="5"><strong>5. CURSOS ONLINE:</strong></p>
        <p>5.1. Este capítulo se refere aos Cursos Online.</p>
        <p>5.1.1. <u>Da Matrícula:</u> A vaga do Participante somente será reservada e concretizada após a devida
          comprovação de pagamento pela instituição financeira competente.</p>
        <p>5.1.2. Do Cancelamento e Reembolso por parte do Participante:</p>
        <p>a) Cancelamento:</p>
        <p>i. No prazo de até 7 (sete) dias corridos após o pagamento do Curso Online, o Participante terá o direito de
          solicitar o cancelamento da matrícula, bem como a devolução integral do valor pago, descontados os encargos
          administrativos. A partir da aprovação da solicitação de cancelamento pela <strong>STARTSE</strong>, o
          reembolso ao Participante ocorrerá dentro de até 7 (sete) dias úteis; e</p>
        <p>ii. A partir do 8º (oitavo) dia corrido após o pagamento do Curso Online, o Participante terá o direito de
          solicitar o cancelamento da matrícula, no entanto, não haverá qualquer tipo de reembolso ao Participante do
          valor pago pelo Curso Online.</p>
        <p>5.1.2.1. <u>Suspensão e/ou Cancelamento por parte da <strong>STARTSE</strong>:</u></p>
        <p>i. A <strong>STARTSE</strong> poderá suspender ou cancelar a disponibilidade de um Curso Online, de maneira
          imotivada, ou seja, a seu único exclusivo critério, mediante o reembolso integral ao Participante, sendo que
          a <strong>STARTSE</strong> não incorrerá em nenhuma multa; e</p>
        <p>ii. Na hipótese do item (i) acima, caberá ao Participante optar pelo reembolso integral do valor pago pelo
          Curso Online suspenso e/ou cancelado ou uma nova inscrição em outro Curso Online de igual valor a que esteja
          disponível.</p>

        <p id="6"><strong>6. CONTRATOS DE PATROCÍNIO:</strong></p>
        <p>6.1. Este capítulo se refere aos Contratos de Patrocínios.</p>
        <p>6.1.1 Nos contratos de patrocínio, a Contratante poderá solicitar o cancelamento, respeitando as seguintes
          condições para reembolso:</p>
        <p>a) Pedido de cancelamento com mais de 60 (sessenta) dias antes da data de entrega: a Contratante fará jus ao
          reembolso de 100% (cem por cento) do valor total do Contrato;</p>
        <p>b) Pedido de cancelamento entre 45 (quarenta e cinco) e 60 (sessenta) dias antes da data de entrega: a
          Contratante fará jus ao reembolso de 50% (cinquenta por cento) do valor total do Contrato;</p>
        <p>c) Pedido de cancelamento entre 30 (trinta) e 45 (quarenta e cinco) dias antes da data de entrega: a
          Contratante fará jus ao reembolso de 30% (trinta por cento) do valor total do Contrato;</p>
        <p>d) Pedido de cancelamento com 30 (trinta) dias ou menos antes da data de entrega: não haverá reembolso.</p>
        <p>Caso o pedido de cancelamento ocorra antes do pagamento integral dos valores pactuados, a Contratante
          permanecerá obrigada a realizar o pagamento proporcional ao serviço já executado, deduzido o valor do
          reembolso previsto nas alíneas acima.</p>

        <p id="7"><strong>7. DISPOSIÇÕES GERAIS:</strong></p>
        <p>7.1. <u>Do Procedimento para Solicitação de Reagendamento, Cancelamento e/ou Reembolso:</u> O Participante
          e/ou Contratante deverá solicitar o reagendamento, cancelamento e/ou reembolso através do e-mail
          atendimento@startse.com ou demais canais de atendimento disponíveis na plataforma da <strong>STARTSE</strong>;
        </p>
        <p>7.2. <u>Dos Pagamentos Duplicados:</u> Caso o Contratante e/ou Participante tenha pagado duas vezes um mesmo
          Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop e/ou Curso Online,
          a <strong>STARTSE</strong> irá realizar o reembolso do valor duplicado após a confirmação no meio de pagamento
          utilizado;</p>
        <p>7.3. O Participante e/ou o Contratante declara ter recebido a presente Política pela internet, lido e
          compreendido seu inteiro teor, redigido em linguagem clara e consciente dos direitos e condicionantes que
          limitam o cumprimento do seu objeto, anuindo com todos os seus termos.</p>

        <p style={{marginTop: 32}}>São Paulo, 15 de Maio de 2023.</p>
        <p><strong>STARTSE INFORMACOES E SISTEMAS LTDA.</strong></p>
      </Content>

      <BackToTop/>
    </Container>
  )
}
