import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  font-size: 10px !important;

  td {
    padding: 10px;
  }  
  
`;
