import React from "react";
import Navbar from "../../../components/Navbar";
import BackToTop from "../../../components/BackToTop";
import { Container, NavbarGroup, Content } from "../style";
import { StyledTable } from "./style";

const General_v3 = () => {
  return (
    <Container>
      <Container id="back-to-top-anchor" />
      <Navbar />

      <NavbarGroup aria-label="Conteúdo da página">
        <p>Índice</p>

        <ul>
          <li>
            <a href="#1">
              <span>
                <strong>1.</strong> Imersões internacionais regulares e/ou segmentadas
              </span>
            </a>
          </li>

          <li>
            <a href="#2">
              <span>
                <strong>2.</strong> Imersões internacionais exclusivas e corporativas
              </span>
            </a>
          </li>

          <li>
            <a href="#3">
              <span>
                <strong>3.</strong> Turmas melhor escolha
              </span>
            </a>
          </li>

          <li>
            <a href="#4">
              <span>
                <strong>4.</strong> Cursos presenciais
              </span>
            </a>
          </li>

          <li>
            <a href="#5">
              <span>
                <strong>5.</strong> Cursos online
              </span>
            </a>
          </li>

          <li>
            <a href="#6">
              <span>
                <strong>6.</strong> Eventos e Workshops
              </span>
            </a>
          </li>

          <li>
            <a href="#7">
              <span>
                <strong>7.</strong> Contratos de Patrocínios
              </span>
            </a>
          </li>

          <li>
            <a href="#8">
              <span>
                <strong>8.</strong> Disposições gerais
              </span>
            </a>
          </li>
        </ul>
      </NavbarGroup>

      <Content>
        <p style={{ paddingTop: 64, color: "#0080ED" }}>
          <strong>Quadro-resumo: condições de cancelamento e remarcação</strong>
        </p>

        <Container style={{ overflowX: "auto", maxHeight: "610px", overflowY: "auto" }}>
          <StyledTable>
            <tr>
              <th>Modalidade</th>
              <th>Substituição de Participante</th>
              <th>Remarcação pelo Participante/Grupo</th>
              <th>Cancelamento pelo Participante/Grupo</th>
              <th>Pedido de conversão em crédito</th>
              <th>Cancelamento pela STARTSE</th>
            </tr>
            <tr>
              <td>
                Imersões Internacionais <br />
                Regulares e/ou <br />
                Segmentadas
              </td>
              <td>
                Antecedência mínima:
                <br /> 60 dias
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                Limite: uma vez
                <br />
                <br />
                Prazo: 12 meses a partir da data da compra
                <br />
                <br />
                Antecedência igual ou superior a 60 dias
                <br />
                •Programas de mesmo valor:
                <br /> taxa adicional de 10%
                <br />
                • Novo Programa de valor superior:
                <br />
                pagamento da taxa ou da diferença entre
                <br />
                os Programas, o que for maior
                <br />
                <br />
                Antecedência inferior a 60 dias
                <br />
                • Programas de mesmo valor: taxa adicional
                <br />
                de 40%
                <br />
                • Novo Programa de valor superior:
                <br />
                pagamento da taxa ou da diferença entre
                <br /> os Programas, o que for maior
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                Em até 7 dias após o pagamento: <br />
                reembolso de 100% <br />
                <br />
                Em até 60 dias antes do início:
                <br />
                reembolso de 30%
                <br /> <br />
                Com menos de 60 dias: <br />
                sem reembolso
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                Prazo de uso: 12 meses
                <br />
                <br />
                Em até 7 dias após o pagamento: <br />
                conversão
                <br />
                de 100% do valor
                <br />
                <br />
                Em até 60 dias antes do início:
                <br />
                conversão de 30%
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                Em até 60 dias antes do início:
                <br />
                reembolso de 100% <br />
                <br />
                Em menos de 60 dias:
                <br /> reembolso de 120%
              </td>
            </tr>
            <tr>
              <td>Imersões Internacionais Exclusivas e Corporativas</td>
              <td>Antecedência mínima: 45 dias</td>
              <td>
                Condição: solicitação conjunta e não individual <br />
                <br />
                Limite: uma vez <br />
                <br />
                Antecedência igual ou superior a 60 dias: sem ônus <br />
                <br />
                Antecedência inferior a 60 dias: <br />
                taxa adicional de 20%
              </td>
              <td>
                Em até 7 dias após o pagamento: reembolso de 100% <br />
                <br />
                Em até 120 dias antes do início: reembolso de 90% <br />
                <br />
                Entre 120 e 90 dias: reembolso de 85% <br />
                <br />
                Entre 90 e 60 dias: reembolso de 70% <br />
                <br />
                Entre 60 e 30 dias: reembolso de 70% <br />
                <br />
                Com menos de 30 dias: sem reembolso
              </td>
              <td>
                Prazo de uso: 12 meses
                <br />
                <br /> Em até 120 dias antes do início: conversão de 100% <br />
                <br />
                Entre 120 e 90 dias: conversão de 95% <br />
                <br />
                Entre 90 e 60 dias: conversão de 80% <br />
                <br />
                Entre 60 e 30 dias: conversão de 70%
              </td>
              <td>
                Até 30 dias antes do início: reembolso de 100% <br />
                <br />
                Entre 30 e 10 dias: reembolso de 110%
                <br />
                <br />
                Com menos de 10 dias: reembolso de 120%
              </td>
            </tr>
            <tr>
              <td>Turmas Melhor Escolha</td>
              <td>Não aplicável</td>
              <td>
                Limite: duas vezes
                <br /> Antecedência mínima: 30 dias
              </td>
              <td>Em até 7 dias após o pagamento: reembolso de 100%</td>
              <td>Não aplicável</td>
              <td>Extensão do prazo de uso da modalidade, sem reembolso</td>
            </tr>
            <tr>
              <td>Cursos Presenciais</td>
              <td>Antecedência mínima: 30 dias</td>
              <td>
                Limite: uma vez <br /> <br />
                Antecedência mínima de 30 dias: sem ônus
                <br /> <br />
                Antecedência inferior a 30 dias = multa adicional de 25%
              </td>
              <td>
                Em até 7 dias após o pagamento: reembolso de 100% <br />
                <br />
                Em até 60 dias antes do início: reembolso de 90% <br />
                <br />
                Entre 60 e 30 dias: reembolso de 80% <br />
                <br />
                Com menos de 30 dias: reembolso de 75%
              </td>
              <td>
                Prazo de uso: 6 meses <br />
                <br />
                Em até 60 dias antes do início: conversão de 100% <br />
                <br />
                Entre 60 e 30 dias: conversão de 90% <br />
                <br />
                Com menos de 30 dias: conversão de 80%
              </td>
              <td>
                Nova inscrição de igual valor ou reembolso, nos termos abaixo: <br />
                <br />
                Até 30 dias antes do início: reembolso de 100% <br />
                <br />
                Entre 30 e 10 dias: reembolso de 110% <br />
                <br />
                Com menos de 10 dias: reembolso de 120%
              </td>
            </tr>
            <tr>
              <td>Cursos Online</td>
              <td>Não aplicável</td>
              <td>
                Limite: 1 vez <br />
                <br /> Sem antecedência mínima
                <br />
                <br /> Sem ônus
              </td>
              <td>
                Em até 7 dias após o pagamento: reembolso de 100% <br />
                <br />
                Com menos de 30 dias antes do início: reembolso de 90%
              </td>
              <td>Prazo de uso: 6 meses Conversão de 100% do valor</td>
              <td>Reembolso integral ou nova inscrição de igual valor</td>
            </tr>
            <tr>
              <td>Eventos e Workshops</td>
              <td>Não aplicável</td>
              <td>Não aplicável</td>
              <td>
                Em até 7 dias após o pagamento: reembolso de 100% <br /> <br />
                Em até 8 dias antes do início: reembolso de 90% <br /> <br />
                Com menos de 7 dias: reembolso de 50%
              </td>
              <td>Prazo de uso: 6 meses Conversão de 100% do valor</td>
              <td>Reembolso integral ou nova inscrição de igual valor</td>
            </tr>
            <tr>
              <td>Contratos de Patrocínio</td>
              <td>Não aplicável</td>
              <td>Não aplicável</td>
              <td>
                Em até 60 dias antes do início: reembolso de 100% <br /> <br />
                Entre 45 e 60 dias reembolso de 50% <br /> <br />
                Entre 30 e 45 dias antes: reembolso de 30% <br /> <br />
                Com 30 dias ou menos: sem reembolso
              </td>
              <td>Não aplicável</td>
              <td>Não aplicável</td>
            </tr>
            <tr>
              <td>Observações</td>
              <td colSpan={5} style={{ textAlign: "justify" }}>
                Em nenhuma hipótese de substituição de participante, remarcação, cancelamento ou
                conversão em crédito a STARTSE reembolsará despesas extras dos Participantes ou
                Grupos, como passagens aéreas, taxa pró-turismo, despesas com expedição de
                passaportes e obtenção de vistos consulares, custo de vacinas, taxas com expedição e
                carregamento de bagagens e malas, hospedagem, despesas com alimentação, serviços de
                quarto, despesas decorrentes de diárias, deslocamentos e similares.
              </td>
            </tr>
          </StyledTable>
        </Container>

        <h1 style={{ marginTop: 50 }}>
          Políticas Gerais de Cancelamento e Remarcação V3 – Compras a partir de 30/11/2024
        </h1>

        <p>
          A presente política tem por objetivo informar sobre as regras estabelecidas para as
          matrículas, reagendamentos, cancelamentos e reembolsos relacionados a todos os Eventos
          Presenciais (tais como Cursos Presenciais, Workshops, Eventos, Programas de Imersões
          Executivas Internacionais (“Eventos Presenciais”), bem como a todos os Cursos Online
          (“Cursos Online”), cujas inscrições se dão no site da <strong>STARTSE</strong> ou através
          de seus canais de atendimento e vendas, com amparo no artigo 49 da Lei nº 8.078/1990
          (“Política”).
        </p>
        <p>
          O Participante que se inscrever, e/ou o Contratante que inscrever seus colaboradores ou
          convidados, nos Eventos Presenciais e/ou Cursos Online da <strong>STARTSE</strong>, atesta
          que leu integralmente e está de acordo com a presente Política, com os termos de uso e
          política de privacidade referentes à mesma.
        </p>
        <p id="1">
          <strong>1. IMERSÕES INTERNACIONAIS REGULARES E/OU SEGMENTADAS:</strong>
        </p>
        <p>
          1.1. Este capítulo se refere aos Programas de Imersões Executivas Internacionais, cuja
          compra é feita de maneira individual e realizada em grupos abertos, podendo ser segmentado
          ou não, tais como: “Missão Vale do Silício, Missão Vale do Silício Agro, Missão China,
          Missão China Varejo” e similares, por exemplo.
        </p>
        <p>
          1.1.1. <u>Da Matrícula:</u> A vaga do Participante somente será reservada e concretizada
          após a devida comprovação de pagamento pela instituição financeira competente.
        </p>
        <p>
          1.1.2. <u>Da Troca de Participantes:</u> A troca de Participante deverá ser realizada
          diretamente na plataforma da <strong>STARTSE</strong> (visualizar compra {">"}{" "}
          participantes {">"} alterar), com até 60 (sessenta) dias de antecedência da realização do
          Programa de Imersão Internacional.
        </p>
        <p>
          1.1.2.1. Caso não haja tal comunicação para alteração, o substituto do Participante não
          poderá fazer parte do Programa de Imersão Executiva Internacional e, ainda, poderá
          prejudicar a experiência do restante do grupo, por conta de motivos operacionais.
        </p>
        <p>1.1.3. Do Reagendamento, Cancelamento e Reembolso:</p>
        <p>
          1.1.3.1. <u>Por parte do Participante:</u>
        </p>
        <p>a) Reagendamento:</p>
        <p>
          i. O Participante poderá solicitar o reagendamento de sua inscrição em até 60 (sessenta)
          dias antes da realização do Programa de Imersão Executiva Internacional adquirido, por 1
          (uma) só vez, mediante pagamento adicional de uma taxa de 10% (dez por cento) sobre o
          valor vigente do programa em questão, desde que este seja aproveitado em até 12 (doze)
          meses a contar da data da compra e que o novo Programa de Imersão Executiva Internacional
          selecionado seja de igual valor. Na hipótese de o novo Programa de Imersão Executiva
          Internacional mostrar-se superior ao do programa inicialmente adquirido e a diferença
          havida entre os valores dos programas for maior que a taxa de 10% (dez por cento)
          mencionada, o Participante deverá pagar o valor da diferença em detrimento da taxa;
        </p>
        <p>
          ii. O Participante poderá solicitar o reagendamento de sua inscrição faltando menos de 60
          (sessenta) dias para a data de início, também por 1 (uma) só vez e dentro do prazo de 12
          (doze) meses a contar da solicitação de reagendamento, estando sujeito à cobrança de uma
          taxa de 40% (quarenta por cento) sobre o valor vigente do programa em questão. Na hipótese
          de o novo Programa de Imersão Executiva Internacional mostrar-se superior ao do programa
          inicialmente adquirido e a diferença havida entre os valores dos programas for maior que a
          taxa de 40% (quarenta por cento) mencionada, o Participante deverá pagar o valor da
          diferença em detrimento da taxa. A presente previsão se aplica, ainda, em casos de
          Participantes que possuírem cortesia no acesso aos programas;
        </p>
        <p>
          iii. Em casos de impossibilidade de participação por motivos de saúde, devidamente
          comprovados por atestado médico ou laudo, o Participante poderá solicitar a remarcação da
          sua participação no Programa de Imersão Executiva Internacional para uma data futura,
          dentro do período de até 12 (doze) meses, sem incidência de multas ou necessidade de
          quitação da diferença de valores eventualmente existente entre os programas. A remarcação
          estará sujeita à disponibilidade de novas turmas;
        </p>
        <p>
          iv. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens
          aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos
          consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas,
          hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias
          e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele
          diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a
          responsabilidade da <strong>STARTSE</strong>;
        </p>
        <p>
          v. O reagendamento será aplicado apenas para Imersões Internacionais, não podendo ser
          aplicado para Cursos Presenciais, Cursos Online, Eventos e/ou Workshops em território
          nacional; e
        </p>
        <p>
          vi. O Participante que optar por não reagendar imediatamente o Programa de Imersão
          Executiva Internacional e não escolher uma nova turma no ato da remarcação, deverá efetuar
          tal escolha em até 12 (doze) meses, prazo este definido para validade do reagendamento,
          após o qual será extinto, sendo certo que o Participante, ao escolher a turma desejada,
          estará sujeito ao custo vigente do referido Programa, devendo arcar com eventual diferença
          tarifária.
        </p>
        <p>b) Cancelamento e Reembolso:</p>
        <p>
          i. No prazo de até 7 (sete) dias corridos após o pagamento do Programa de Imersão
          Executiva Internacional, o Participante poderá cancelar sua inscrição no Programa de
          Imersão Executiva Internacional com direito a reembolso total do valor pago ou conversão
          integral do valor em crédito para uso em até 12 (doze) meses em produtos da{" "}
          <strong>STARTSE</strong>, conforme escolha do Participante;
        </p>
        <p>
          ii. Para cancelamentos realizados até 60 (sessenta) dias antes do início do Programa de
          Imersão Executiva Internacional, o Participante terá direito ao reembolso de 30% (trinta
          por cento) do valor total do Programa;
        </p>
        <p>
          iii. Cancelamentos efetuados com menos de 60 (sessenta) dias antes do início do Programa
          não darão direito a reembolso.
        </p>
        <p>
          iv. Caso o pagamento do Programa tenha sido parcelado, a <strong>STARTSE</strong>{" "}
          reserva-se o direito de exigir o pagamento das parcelas vencidas e das parcelas
          necessárias para garantir o cumprimento dos percentuais de reembolso acima estipulados. O
          Participante autoriza o abatimento dos valores já pagos, caso aplicável.
        </p>
        <p>
          v. A ausência ou o atraso do Participante nos dias do Programa de Imersão Executiva
          Internacional, sob qualquer hipótese, não dará direito a qualquer reembolso, troca,
          bonificação, desconto ou transferência de inscrição;
        </p>
        <p>
          vi. Em casos de faturamento (confirmação com fatura antecipada) será considerada a data em
          que houver a emissão do respectivo documento;
        </p>
        <p>
          vii. Se a solicitação de cancelamento for aprovada pela <strong>STARTSE</strong>, o
          Participante receberá um e-mail contendo o valor a ser restituído e um formulário para ser
          preenchido. O reembolso ocorrerá no 20° dia do mês do envio do formulário, caso este seja
          enviado até o 10° dia. Se o envio do formulário ocorrer após o 10° dia, o reembolso
          acontecerá no 20° dia do mês subsequente; e
        </p>
        <p>
          viii. Caso o Participante opte por converter o valor oriundo do cancelamento em crédito
          para uso na plataforma <strong>STARTSE</strong>, o crédito poderá ser utilizado em Cursos
          Presenciais, Cursos Online, Eventos e/ou Workshops, dentro do prazo de 12 (doze) meses a
          contar da data original de compra do Programa. Após esse prazo, o crédito expirará, e não
          será possível solicitar o seu reembolso.
        </p>
        <p>
          1.1.3.2. <u>Do Grupo Mínimo:</u>
        </p>
        <p>
          i. Caso o número de Participantes, após eventuais cancelamentos, venha a ser inferior a 15
          (quinze) participantes, a STARTSE reserva-se ao direito de não realizar o Programa de
          Imersão Executiva Internacional, sem incidência de qualquer multa à{" "}
          <strong> STARTSE</strong> neste sentido; e
        </p>
        <p>
          ii. Nesse caso, os Participantes remanescentes cumprirão a regra de cancelamento por parte
          do Participante, sendo que as regras de reembolso serão orientadas pela data de
          solicitação de cancelamento do 15º (décimo quinto) Participante remanescente do grupo
          exclusivo.
        </p>
        <p>
          1.1.3.3.{" "}
          <u>
            Por parte da <strong>STARTSE</strong>:
          </u>
        </p>
        <p>a) Reagendamento e/ou Cancelamento:</p>
        <p>
          i. A <strong>STARTSE</strong> poderá reagendar ou cancelar um Programa de Imersão
          Executiva Internacional, de maneira imotivada, ou seja, a seu único exclusivo critério, da
          seguinte forma:
        </p>
        <p>
          a) Com aviso prévio de até 60 (sessenta) dias antes do início do Programa de Imersão
          Executiva Internacional, o reembolso ao Participante será integral e a{" "}
          <strong>STARTSE</strong> não incorrerá em qualquer multa;
        </p>
        <p>
          b) Com aviso prévio inferior a 60 (sessenta) dias antes do início do Programa de Imersão
          Executiva Internacional, o reembolso ao Participante será integral e a{" "}
          <strong>STARTSE</strong> incorrerá em uma multa de 20% (vinte por cento) do valor da
          Imersão Internacional reagendada ou cancelada, a ser paga ao Participante;
        </p>
        <p>
          ii. Nas hipóteses do item (i) acima, caberá ao Participante optar pelo reembolso integral
          do valor pago pelo Programa de Imersão Executiva Internacional reagendado e/ou cancelado
          ou uma nova inscrição em outro Programa de Imersão Executiva Internacional de igual valor
          a ser realizado. Caso o Participante tenha interesse em um Programa de maior valor, deverá
          promover a quitação da diferença de valores havida entre os programas;
        </p>
        <p>
          iii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens
          aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos
          consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas,
          hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias
          e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele
          diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a
          responsabilidade da <strong>STARTSE</strong>; e
        </p>
        <p>
          iv. Na hipótese de reagendamento e/ou cancelamento de Imersões Internacionais decorrentes
          de caso fortuito, motivados por força maior, causas extremas ou qualquer outro fato alheio
          à <strong>STARTSE</strong>, esta não incorrerá em qualquer tipo de multa. Entretanto,
          nestes casos, a <strong>STARTSE</strong> possibilitará ao Participante reagendar uma nova
          inscrição em outro Programa de Imersão Executiva Internacional de igual valor a ser
          realizado, pelo prazo de até 12 (doze) meses. Caso o Participante tenha interesse em um
          Programa de maior valor, deverá promover a quitação da diferença de valores havida entre
          os programas.
        </p>
        <p id="2">
          <strong>2. IMERSÕES INTERNACIONAIS EXCLUSIVAS E CORPORATIVAS:</strong>
        </p>
        <p>
          2.1. Este capítulo trata dos Programas de Imersões Executivas Internacionais compradas de
          maneira individual em grupos exclusivos, podendo ser segmentado ou não, bem como das
          adquiridas por pessoa jurídica (“Contratante”) para colaboradores e/ou convidados (ex:
          clientes e fornecedores);
        </p>
        <p>
          2.1.1. Da Matrícula: A vaga do Participante somente será reservada e concretizada após a
          devida comprovação de pagamento pela instituição financeira, e no caso das corporativas,
          somente será reservada e concretizada após a devida assinatura do contrato de prestação de
          serviço entre a <strong>STARTSE</strong> e a Contratante.
        </p>
        <p>
          2.1.1.1. No caso de os Participantes serem convidados da Contratante, os mesmos estarão
          submetidos ao contrato estabelecido e celebrado entre a Contratante e a{" "}
          <strong>STARTSE</strong>.
        </p>
        <p>
          2.1.2. Da Troca de Participantes: deverá ser alterada diretamente na plataforma
          (visualizar compra {">"} participantes {">"} alterar), com até 45 (quarenta e cinco) dias
          de antecedência da realização do Programa de Imersão Internacional.
        </p>
        <p>
          2.1.2.1. Caso não haja tal comunicação para alteração, o substituto do Participante não
          poderá fazer parte do Programa de Imersão Executiva Internacional Programa de Imersão
          Executiva Internacional e, ainda, poderá prejudicar a experiência do restante do grupo,
          por conta de motivos operacionais.
        </p>
        <p>
          2.1.3. <u>Do Reagendamento</u>, <u>Conversão em Créditos</u>,{" "}
          <u>Cancelamento e Reembolso:</u>
        </p>
        <p>
          2.1.3.1. <u>Reagendamento:</u>
        </p>
        <p>
          i. O grupo, de forma unificada (100% dos participantes), poderá solicitar por 1 (uma) vez
          o reagendamento da data do Programa de Imersão Executiva Internacional do grupo, sem
          multas, em até 60 dias antes da realização do programa; caso a solicitação se dê a menos
          de 60 (sessenta) dias para o início do Programa de Imersão Executiva Internacional, o
          grupo ficará sujeito à cobrança de uma taxa de 20% (vinte por cento) sobre o valor vigente
          do programa em questão.
        </p>
        <p>
          ii. Após os períodos acima citados, serão aplicadas as regras de cancelamento (ver
          cláusula 2.1.3.3);
        </p>
        <p>
          iii. A nova data para o grupo exclusivo será definida em comum acordo com a{" "}
          <strong>STARTSE</strong>; e
        </p>
        <p>
          iv. Em nenhuma hipótese serão reembolsadas despesas extras dos Participantes, como
          passagens aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de
          vistos consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e
          malas, hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de
          diárias e deslocamentos, que serão de responsabilidade exclusiva dos Participantes e por
          eles diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a
          responsabilidade da <strong>STARTSE</strong>.
        </p>
        <p>
          2.1.3.2. <u>Impossibilidade de Reagendamento por Parte do Participante:</u>
        </p>
        <p>
          i. Por tratar-se de um grupo exclusivo, o Participante não poderá solicitar reagendamento
          individual para outro Programa de Imersão Executiva Internacional e deverá solicitar o
          cancelamento de sua inscrição no grupo exclusivo, sabendo que poderá não cumprir a regra
          do grupo mínimo de 15 participantes (cláusula 2.1.3.5); e
        </p>
        <p>
          ii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens
          aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos
          consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas,
          hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias
          e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele
          diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a
          responsabilidade da <strong>STARTSE</strong>.
        </p>
        <p>
          2.1.3.3. <u>Cancelamento por Parte do Participante ou Contratante:</u>
        </p>
        <p>
          i. No prazo de até 7 (sete) dias corridos após o pagamento do Programa de Imersão
          Executiva Internacional, o Participante poderá cancelar sua inscrição no Programa de
          Imersão Executiva Internacional com direito a reembolso total do valor pago ou conversão
          integral do valor em crédito para uso em até 12 (doze) meses em produtos da{" "}
          <strong>STARTSE</strong>, conforme escolha do Participante;
        </p>
        <p>
          ii. Para cancelamentos realizados até 120 (cento e vinte) dias antes do início do Programa
          de Imersão Executiva Internacional, o Participante terá direito ao reembolso de 90%
          (noventa por cento) do valor total do Programa.
        </p>
        <p>
          iii. Cancelamentos efetuados entre 120 (cento e vinte) dias e 90 (noventa) dias antes do
          início do Programa darão direito ao reembolso de 85% (oitenta e cinco por cento) do valor
          total do Programa.
        </p>
        <p>
          iv. Cancelamentos realizados entre 90 (noventa) dias e 60 (sessenta) dias antes do início
          do Programa darão direito ao reembolso de 70% (setenta por cento) do valor total do
          Programa.
        </p>
        <p>
          v. Cancelamentos efetuados entre 60 (sessenta) dias e 30 (trinta) dias antes do início do
          Programa darão direito ao reembolso de 60% (sessenta por cento) do valor total do
          Programa;
        </p>
        <p>
          vi. Cancelamentos efetuados com menos de 30 (trinta) dias antes do início do Programa não
          darão direito a reembolso;
        </p>
        <p>
          vii. Caso o pagamento do Programa tenha sido parcelado, a <strong>STARTSE</strong>{" "}
          reserva-se o direito de exigir o pagamento das parcelas vencidas e das parcelas
          necessárias para garantir o cumprimento dos percentuais de reembolso acima estipulados. O
          Participante autoriza o abatimento dos valores já pagos, caso aplicável;
        </p>
        <p>
          viii. A ausência ou atraso do Participante nos dias do Programa de Imersão Executiva
          Internacional, sob qualquer hipótese, não dará direito a reembolso, troca, bonificação,
          desconto ou transferência de inscrição;
        </p>
        <p>
          ix. Em casos de faturamento (confirmação com fatura antecipada) será considerada a data em
          que houver a emissão do respectivo documento;
        </p>
        <p>
          x. Se a solicitação de cancelamento for aprovada pela <strong>STARTSE</strong>, o
          Participante receberá um e-mail contendo o valor a ser restituído e um formulário para ser
          preenchido. O reembolso ocorrerá no 20° dia do mês do envio do formulário, caso este seja
          enviado até o 10° dia. Se o envio do formulário ocorrer após o 10° dia, o reembolso
          acontecerá no 20° dia do mês subsequente;
        </p>
        <p>
          2.1.3.4. <u>Conversão em Créditos por escolha do Contratante/Participante</u>
        </p>
        <p>
          i. Em até 120 (cento e vinte) dias anteriores à data de início do Programa, a{" "}
          <strong>STARTSE </strong>
          permitirá ao Contratante/Participante isenção de multa contratual, desde que este concorde
          em substituir o reembolso por disponibilização de crédito na Plataforma{" "}
          <strong>STARTSE</strong>, equivalente a 100% (cem por cento) do valor do Programa, podendo
          utilizá-lo para contratar outros produtos <strong>STARTSE</strong> da mesma modalidade;
        </p>
        <p>
          ii. Entre 120 (cento e vinte) e90 (noventa) dias anteriores à data de início do Programa,
          a <strong> STARTSE</strong> permitirá ao Contratante/Participante isenção de multa
          contratual, desde que este concorde em substituir o reembolso por disponibilização de
          crédito na Plataforma
          <strong> STARTSE</strong>, equivalente a 95% (noventa e cinco por cento) do valor do
          Programa, podendo utilizá-lo para contratar outros produtos <strong>STARTSE</strong> da
          mesma modalidade;
        </p>
        <p>
          iii. Entre 90 (noventa) e 60 (sessenta) dias anteriores à data de início do Programa, a
          <strong> STARTSE</strong> permitirá ao Contratante/Participante isenção de multa
          contratual, desde que este concorde em substituir o reembolso por disponibilização de
          crédito na Plataforma <strong>STARTSE</strong>, equivalente a 80% (oitenta por cento) do
          valor do Programa;
        </p>
        <p>
          iv. Entre 60 (sessenta) e 30 (trinta) dias anteriores à data de início do Programa, a
          <strong> STARTSE</strong> permitirá ao Contratante/Participante isenção de multa
          contratual, desde que este concorde em substituir o reembolso por disponibilização de
          crédito na Plataforma <strong> STARTSE</strong>, equivalente a 70% (setenta por cento) do
          valor do Programa; e
        </p>
        <p>
          v. Caso o Contratante/Participante opte por converter o valor do Programa em crédito para
          uso na Plataforma <strong>STARTSE</strong>, observadas as condições acima indicadas,
          deverá utilizá-lo em até 12 (doze) meses após sua confirmação pela{" "}
          <strong>STARTSE</strong>, prazo de validade para créditos, não sendo possível o seu
          reembolso.
        </p>
        <p>
          2.1.3.5. <u>Grupo Mínimo:</u>
        </p>
        <p>
          i. Caso o número de Participantes do grupo exclusivo, após eventuais cancelamentos, venha
          a ser inferior a 15 (quinze) participantes, a <strong>STARTSE</strong> reserva-se ao
          direito de não realizar o Programa de Imersão Executiva Internacional, sem incidência de
          qualquer multa à<strong> STARTSE</strong> neste sentido; e
        </p>
        <p>
          ii. Nesse caso, os Participantes remanescentes cumprirão a regra de cancelamento por parte
          do Participante, sendo que as regras de reembolso serão orientadas pela data de
          solicitação de cancelamento do 15º (décimo quinto) Participante remanescente do grupo
          exclusivo.
        </p>
        <p>
          2.1.3.6.{" "}
          <u>
            Reagendamento e/ou Cancelamento por parte da <strong>STARTSE</strong>:
          </u>
        </p>
        <p>
          i. A <strong>STARTSE</strong> poderá reagendar ou cancelar um Programa de Imersão
          Executiva Internacional, de maneira imotivada, ou seja, a seu único exclusivo critério, da
          seguinte forma:
        </p>
        <p>
          a) Com aviso prévio de até 60 (sessenta) dias antes do início do Programa de Imersão
          Executiva Internacional, o reembolso ao Contratante será integral e a{" "}
          <strong>STARTSE</strong> não incorrerá em qualquer multa;
        </p>
        <p>
          b) Com aviso prévio inferior a 60 (sessenta) dias antes do início do Programa de Imersão
          Executiva Internacional, o reembolso ao Contratante será integral e a{" "}
          <strong>STARTSE</strong> incorrerá em uma multa de 20% (vinte por cento) do valor da
          Imersão Internacional reagendada ou cancelada, a ser paga ao Participante;
        </p>
        <p>
          ii. Na hipótese do item (i) acima, caberá ao Contratante optar pelo reembolso integral do
          valor pago pelo Programa de Imersão Executiva Internacional reagendada e/ou cancelada ou
          uma nova inscrição em outro Programa de Imersão Executiva Internacional de igual valor a
          ser realizada. Caso o Contratante tenha interesse em um Programa de maior valor, deverá
          promover a quitação da diferença de valores havida entre os programas;
        </p>
        <p>
          iii. Em nenhuma hipótese serão reembolsadas despesas extras dos Contratantes/Participantes
          como passagens aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção
          de vistos consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e
          malas, hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de
          diárias e deslocamentos, que serão de responsabilidade exclusiva dos
          Contratantes/Participantes e por eles diretamente pagas ao estabelecimento ou empresa
          prestadora ou fornecedora, sem a responsabilidade da <strong>STARTSE</strong>; e
        </p>
        <p>
          iv. Na hipótese de reagendamento e/ou cancelamento de Imersões Internacionais decorrentes
          de caso fortuito, motivados por força maior, causas extremas ou qualquer outro fato alheio
          à <strong>STARTSE</strong>, esta não incorrerá em qualquer tipo de multa. Entretanto,
          nestes casos, a <strong>STARTSE</strong> possibilitará ao Contratante reagendar uma nova
          inscrição em outro Programa de Imersão Executiva Internacional de igual valor a ser
          realizado, pelo prazo de até 12 (doze) meses. Caso o Contratante tenha interesse em um
          Programa de maior valor, deverá promover a quitação da diferença de valores havida entre
          os programas.
        </p>
        <p id="3">
          <strong>3. TURMAS MELHOR ESCOLHA</strong>
        </p>
        <p>
          3.1. Especificamente no que tange à aquisição de produtos / serviços sob a modalidade
          Melhor Escolha, aplicar-se-ão os seguintes termos e condições:
        </p>
        <p>3.1.1. Cursos e Missões Internacionais.</p>
        <p>3.1.1.1. Prazo para uso, reagendamentos e cancelamento.</p>
        <p>
          a) <u>Prazo para uso</u>. Efetuada a compra, o Participante deverá usufruir do Curso /
          Missão Internacional em até 12 (doze) meses, mediante a escolha da turma desejada,
          conforme disponibilidade informada pela <strong>STARTSE</strong>, sendo certo que a{" "}
          <strong>STARTSE</strong> garantirá, no mínimo, 2 (duas) turmas no período de 12 (doze)
          meses após a referida compra. Contudo, caso, por alguma eventualidade, tais turmas não
          sejam formadas, o prazo para uso será prorrogado até que se forme uma nova turma, que será
          a última opção ofertada ao Participante.
        </p>
        <p>
          b)<u> Reagendamento</u>. Efetuada a inscrição em turma disponibilizada pela{" "}
          <strong>STARTSE</strong>, caso o Participante deseje alterar para outra, esta alteração
          será autorizada por até 2 (duas) vezes, desde que solicitada pelo Participante com pelo
          menos 30 (trinta) dias antes da data de início da turma em que estiver matriculado, sendo
          certo que a validade de seu prazo para uso não será alterada, não sendo possível
          reagendamentos quando o Participante estiver matriculado na última opção de oferta
          disponibilizada no período de uso indicado no item “a” acima e não se aplicando as
          condições previstas no item “a” acima caso a<strong> STARTSE</strong> tenha cumprido a
          quantidade mínima de ofertas para o período de validade ali também indicado.
        </p>
        <p>
          c) <u>Cancelamento</u>. O Participante poderá solicitar o cancelamento de sua inscrição
          com reembolso integral em até 7 (sete) dias corridos após a sua compra, conforme Código de
          Defesa do Consumidor (direito de arrependimento). Transcorrido o prazo de 7 (sete) dias
          corridos, o Participante não terá direito a qualquer reembolso, parcial ou total, sendo
          devido o pagamento integral do preço à <strong>STARTSE</strong>.
        </p>
        <p>3.1.2. Cursos presenciais.</p>
        <p>3.1.2.1. Prazo para uso, reagendamentos e cancelamento.</p>
        <p>
          a) <u>Prazo para uso</u>. Efetuada a compra, o Participante deverá usufruir do Curso
          Presencial em até 12 (doze) meses, mediante a escolha da turma desejada, conforme
          disponibilidade informada pela <strong>STARTSE</strong>, sendo certo que a{" "}
          <strong>STARTSE</strong> garantirá, no mínimo, 2 (duas) turmas no período de 12 (doze)
          meses após a referida compra. Contudo, caso, por alguma eventualidade, tais turmas não
          sejam formadas, o prazo para uso será prorrogado até que se forme uma nova turma, que será
          a última opção ofertada ao Participante.
        </p>
        <p>
          b) <u>Reagendamento</u>. Efetuada a inscrição em turma disponibilizada pela{" "}
          <strong>STARTSE</strong>, caso o Participante deseje alterar para outra, esta alteração
          será autorizada por até 2 (duas) vezes, desde que solicitada pelo Participante com pelo
          menos 30 (trinta) dias antes da data de início da turma em que estiver matriculado, sendo
          certo que a validade de seu prazo para uso não será alterada, não sendo possível
          reagendamentos quando o Participante estiver matriculado na última opção de oferta
          disponibilizada no período de uso indicado no item “a” acima e não se aplicando as
          condições previstas no item “a” acima caso a<strong> STARTSE</strong> tenha cumprido a
          quantidade mínima de ofertas para o período de validade ali também indicado.
        </p>
        <p>
          c) <u>Cancelamento</u>. O Participante poderá solicitar o cancelamento de sua inscrição
          com reembolso integral, até 7 (sete) dias corridos após a sua compra, conforme Código de
          Defesa do Consumidor (direito de arrependimento). Transcorrido o prazo de 7 (sete) dias
          corridos, o Participante não terá direito a qualquer reembolso, parcial ou total, sendo
          devido o pagamento integral do preço à <strong>STARTSE</strong>.
        </p>
        <p id="4">
          <strong>4. CURSOS PRESENCIAIS:</strong>
        </p>
        <p>4.1. Este capítulo se refere aos Cursos Presenciais realizados no Brasil.</p>
        <p>
          4.1.1. <u>Da Matrícula</u>: A vaga do Participante somente será reservada e concretizada
          após a devida comprovação de pagamento pela instituição financeira competente.
        </p>
        <p>
          4.1.2. <u>Da Troca de Participantes</u>: A troca de Participante deverá ser realizada
          diretamente na plataforma da <strong>STARTSE</strong> (visualizar compra {">"}{" "}
          participantes {">"} alterar), com até 30 (trinta) dias de antecedência da realização dos
          Cursos Presenciais.
        </p>
        <p>
          4.1.2.1. Caso não haja tal comunicação para alteração, o substituto do Participante não
          poderá fazer parte dos Cursos Presenciais.
        </p>
        <p>
          4.1.3. <u>Do Reagendamento</u>, <u>Conversão em Créditos</u>,{" "}
          <u>Cancelamento e Reembolso</u>:
        </p>
        <p>
          4.1.3.1. <u>Por parte do Participante</u>:
        </p>
        <p>a) Reagendamento:</p>
        <p>
          i. O Participante poderá solicitar o reagendamento de sua inscrição em até 30 (trinta)
          dias antes da realização do Curso Presencial sem ônus, desde que o novo Curso Presencial
          seja de igual valor, sendo que esta ação está limitada a 1 (um) reagendamento no prazo de
          até 12 (doze) meses. Caso o Participante opte por um Curso Presencial de maior valor,
          deverá realizar o pagamento da diferença para efetivar a nova inscrição no Curso
          Presencial desejado;
        </p>
        <p>
          ii. O Participante poderá solicitar o reagendamento de sua inscrição faltando menos de 30 (trinta) dias 
          para a data de realização do Curso Presencial, estando sujeito à cobrança de uma multa de 25% (vinte e cinco por cento) 
          sobre o valor vigente do curso em questão, e desde que o novo Curso Presencial seja de igual valor, 
          sendo que esta ação está limitada a 1 (um) reagendamento no prazo de até 12 (doze) meses. 
          A presente previsão se aplica, ainda, em casos de Participantes que possuírem cortesia no acesso aos programas;
        </p>
        <p>
          iii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens
          aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos
          consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas,
          hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias
          e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele
          diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a
          responsabilidade da <strong>STARTSE</strong>;
        </p>
        <p>b) Conversão em Créditos</p>
        <p>
          i. O Participante poderá optar por converter o valor do Curso Presencial em crédito para
          uso na Plataforma <strong>STARTSE</strong>, com possibilidade de utilização pelo prazo de
          6 (seis) meses após a confirmação da conversão pela <strong>STARTSE</strong>, após o qual
          não será aplicável reembolso;
        </p>
        <p>
          ii. Em até 60 (sessenta) dias antes do início do Curso Presencial, conversão de 100% (cem
          por cento) do valor em créditos;
        </p>
        <p>
          iii. Entre 60 (sessenta) e 30 (trinta) dias antes do início do Curso Presencial, conversão
          de 90% (noventa por cento) do valor em créditos;
        </p>
        <p>
          iv. Com menos de 30 (trinta) dias de antecedência, conversão de 80% (oitenta por cento) do
          valor.
        </p>
        <p>
          v. Caso o Participante opte por um Curso Presencial de maior valor que o crédito que
          possui, o Participante deverá realizar o pagamento da diferença para efetivar a nova
          inscrição desejada; e
        </p>
        <p>
          vi. O crédito será aplicado para Curso Presencial, Curso Online Transmitido ao Vivo,
          Evento ou Workshop, não podendo ser aplicado para Programas de Imersões Executivas
          Internacionais.
        </p>
        <p>c) Cancelamento:</p>
        <p>
          i. No prazo de até 7 (sete) dias corridos após o pagamento do Curso Presencial, o
          Participante poderá cancelar sua inscrição com direito a reembolso total do valor pago ou
          conversão integral do valor em crédito para uso em até 6 (seis) meses em produtos da
          <strong> STARTSE</strong>, conforme escolha do Participante;
        </p>
        <p>
          ii. Para cancelamentos realizados até 60 (sessenta) dias antes do início do Curso
          Presencial, o Participante terá direito ao reembolso de 90% (noventa por cento) do valor
          total do Curso Presencial;
        </p>
        <p>
          iii. Cancelamentos efetuados entre 60 (sessenta) e 30 (trinta) dias antes do início do
          Curso Presencial darão direito ao reembolso de 80% (oitenta por cento) do valor total do
          Curso Presencial;
        </p>
        <p>
          iv. Cancelamentos realizados com menos de 30 (trinta) dias antes do início do Programa
          darão direito ao reembolso de 75% (setenta e cinco por cento) do valor total do Programa;
        </p>
        <p>
          v. A ausência ou o atraso do Participante nos dias do Curso Presencial, sob qualquer
          hipótese, não darão direito a reembolso, troca, bonificação, desconto ou transferência de
          inscrição;
        </p>
        <p>
          vi. Em casos de faturamento (confirmação com fatura antecipada) será considerada a data em
          que houver a emissão do respectivo documento;
        </p>
        <p>
          vii. Se a solicitação de cancelamento for aprovada pela <strong>STARTSE</strong>, o
          Participante receberá um e-mail contendo o valor a ser restituído e um formulário para ser
          preenchido. O reembolso ocorrerá no 20° dia do mês do envio do formulário, caso este seja
          enviado até o 10° dia. Se o envio do formulário ocorrer após o 10° dia, o reembolso
          acontecerá no 20° dia do mês subsequente;
        </p>
        <p>
          4.1.3.2.{" "}
          <u>
            Por parte da <strong>STARTSE</strong>
          </u>
          :
        </p>
        <p>a) Reagendamento e/ou Cancelamento:</p>
        <p>
          i. A <strong>STARTSE</strong> poderá reagendar ou cancelar um Curso Presencial de maneira
          imotivada, ou seja, a seu único exclusivo critério, da seguinte forma:
        </p>
        <p>
          a) Com aviso prévio de até 30 (trinta) dias antes do Curso Presencial, o reembolso ao
          Participante será integral e a <strong>STARTSE</strong> não incorrerá em nenhuma multa;
        </p>
        <p>
          b) Com aviso prévio a partir do 29º (vigésimo nono) dia antes do Curso Presencial e em até
          10 (dez) dias antes do Curso Presencial, o reembolso ao Participante será integral e a
          <strong> STARTSE</strong> incorrerá em uma multa de 10% (dez por cento) do valor do Curso
          Presencial reagendado ou cancelado, a ser paga ao Participante;
        </p>
        <p>
          c) Com aviso prévio a partir do 9º (nono) dia antes do Curso Presencial, o reembolso ao
          Participante será integral e a <strong>STARTSE</strong> incorrerá em uma multa de 20%
          (vinte por cento) do valor do Curso Presencial reagendado ou cancelado, a ser paga ao
          Participante.
        </p>
        <p>
          ii. Nas hipóteses do item (i) acima, caberá ao Participante optar pelo reembolso integral
          do valor pago pelo Curso Presencial reagendado e/ou cancelado ou uma nova inscrição em
          outro Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop de igual
          valor a ser realizado;
        </p>
        <p>
          iii. Em nenhuma hipótese serão reembolsadas despesas extras do Participante como passagens
          aéreas, taxa pró-turismo, despesas com expedição de passaportes e obtenção de vistos
          consulares, custo de vacinas, taxas com expedição e carregamento de bagagens e malas,
          hospedagem, despesas com alimentação, serviços de quarto, despesas decorrentes de diárias
          e deslocamentos, que serão de responsabilidade exclusiva do Participante e por ele
          diretamente pagas ao estabelecimento ou empresa prestadora ou fornecedora, sem a
          responsabilidade da <strong>STARTSE</strong>; e
        </p>
        <p>
          iv. Na hipótese de reagendamento e/ou cancelamento de Curso Presencial decorrentes de caso
          fortuito, motivados por força maior, causas extremas ou qualquer outro fato alheio à
          <strong> STARTSE</strong>, a mesma não incorrerá em qualquer tipo de multa. Entretanto,
          nestes casos, a <strong>STARTSE</strong> possibilitará ao Participante optar pelo
          reembolso integral do valor pago pelo Curso Presencial, Workshop reagendado e/ou cancelado
          ou realizar uma nova inscrição em outro Curso Presencial, Curso Online Transmitido ao
          Vivo, Evento ou Workshop de igual valor a ser realizada.
        </p>
        <p id="5">
          <strong>5. CURSOS ONLINE:</strong>
        </p>

        <p>5.1. Este capítulo se refere aos Cursos Online, transmitidos ao vivo ou não.</p>

        <p>
          5.1.1. <u>Da Matrícula</u>: A vaga do Participante somente será reservada e concretizada
          após a devida comprovação de pagamento pela instituição financeira competente.
        </p>

        <p>
          5.1.2. <u>Do Reagendamento</u>, <u>Conversão em Crédito</u>,{" "}
          <u>Cancelamento e Reembolso por parte do Participante</u>:
        </p>

        <p>a) Reagendamento</p>

        <p>
          i. O Participante poderá solicitar o reagendamento de sua inscrição por 1 (uma) vez antes
          da realização do Curso Online, sem ônus, desde que o novo Curso Online selecionado seja de
          igual valor.
        </p>

        <p>b) Conversão em Crédito</p>
        <p>
          i. O Participante poderá optar por converter o valor total do Curso Online em crédito para
          uso na Plataforma <strong>STARTSE</strong>, com possibilidade de utilização pelo prazo de
          6 (seis) meses após a confirmação da conversão pela <strong>STARTSE</strong>, após o qual
          não será aplicável qualquer reembolso.
        </p>

        <p>c) Cancelamento:</p>

        <p>
          i. No prazo de até 7 (sete) dias corridos após o pagamento do Curso Online, o Participante
          terá o direito de solicitar o cancelamento da matrícula, bem como a devolução integral do
          valor pago, descontados os encargos administrativos. A partir da aprovação da solicitação
          de cancelamento pela <strong>STARTSE</strong>, o reembolso ao Participante ocorrerá dentro
          de até 7 (sete) dias úteis; e
        </p>

        <p>
          ii. Cancelamentos efetuados com menos de 7 (sete) dias antes do início do Curso Online não
          darão direito ao reembolso.{" "}
        </p>

        <p>
          5.1.2.1.{" "}
          <u>
            Suspensão e/ou Cancelamento por parte da <strong>STARTSE</strong>
          </u>
          :
        </p>

        <p>
          i. A <strong>STARTSE</strong> poderá suspender ou cancelar a disponibilidade de um Curso
          Online, de maneira imotivada, ou seja, a seu único exclusivo critério, mediante o
          reembolso integral ao Participante, sendo que a <strong>STARTSE</strong> não incorrerá em
          nenhuma multa; e
        </p>

        <p>
          ii. Na hipótese do item (i) acima, caberá ao Participante optar pelo reembolso integral do
          valor pago pelo Curso Online suspenso e/ou cancelado ou uma nova inscrição em outro Curso
          Online de igual valor a que esteja disponível.
        </p>
        <p id="6">
          <strong>6. EVENTOS E WORKSHOPS</strong>
        </p>

        <p>6.1. Este capítulo se refere aos Eventos e Workshops.</p>

        <p>
          6.1.1. <u>Da Matrícula</u>: A vaga do Participante somente será reservada e concretizada
          após a devida comprovação de pagamento pela instituição financeira competente.
        </p>

        <p>
          6.1.2.{" "}
          <u>Da Conversão em Crédito e do Cancelamento e Reembolso por parte do Participante</u>:
        </p>

        <p>a) Conversão em Crédito</p>

        <p>
          i. O Participante poderá optar por converter o valor total do Evento ou Workshop, antes de
          sua realização, em crédito para uso na Plataforma <strong>STARTSE</strong>, com
          possibilidade de utilização pelo prazo de 6 (seis) meses após a confirmação da conversão
          pela <strong>STARTSE</strong>, após o qual não será aplicável qualquer reembolso.
        </p>

        <p>b) Cancelamento:</p>

        <p>
          i. No prazo de até 7 (sete) dias corridos após o pagamento do Evento ou Workshop, o
          Participante terá o direito de solicitar o cancelamento da matrícula, bem como a devolução
          integral do valor pago, descontados os encargos administrativos. A partir da aprovação da
          solicitação de cancelamento pela <strong>STARTSE</strong>, o reembolso ao Participante
          ocorrerá dentro de até 7 (sete) dias úteis; e
        </p>

        <p>
          ii. Para cancelamentos realizados até 8 (oito) dias antes da realização do Evento ou
          Workshop, o Participante terá direito ao reembolso de 90% (noventa por cento) do valor
          total do Evento ou Workshop;
        </p>

        <p>
          iii. Cancelamentos realizados com menos de 7 (sete) dias antes do início do Evento ou
          Workshop darão direito ao reembolso de 50% (cinquenta por cento) do valor total do Evento
          ou Workshop.
        </p>

        <p>
          iv. Na hipótese de o Evento ou Workshop ter sido adquirido para um grupo de Participantes
          e um ou mais Participantes desejarem cancelar sua participação individual, o Contratante
          do Evento ou Workshop deverá contatar a <strong>STARTSE</strong> para que esta verifique a
          possibilidade de cancelamento de inscrição individual do(s) Participante(s); caso tal
          medida não seja possível, a <strong>STARTSE</strong> poderá orientar o Contratante quanto
          ao cancelamento geral da matrícula.
        </p>

        <p>
          6.1.2.1. Suspensão e/ou Cancelamento por parte da <strong>STARTSE</strong>:
        </p>

        <p>
          i. A <strong>STARTSE</strong> poderá suspender ou cancelar a disponibilidade de um Evento
          ou Workshop, de maneira imotivada, ou seja, a seu único exclusivo critério, mediante o
          reembolso integral ao Participante, sendo que a <strong>STARTSE</strong> não incorrerá em
          nenhuma multa; e
        </p>

        <p>
          ii. Na hipótese do item (i) acima, caberá ao Participante optar pelo reembolso integral do
          valor pago pelo Evento ou Workshop suspenso e/ou cancelado ou uma nova inscrição em outro
          Evento ou Workshop de igual valor a que esteja disponível.
        </p>
        <p id="7">
          <strong>7. CONTRATOS DE PATROCÍNIO:</strong>
        </p>

        <p>7.1. Este capítulo se refere aos Contratos de Patrocínios.</p>

        <p>
          7.1.1 Nos contratos de patrocínio, a Contratante poderá solicitar o cancelamento,
          respeitando as seguintes condições para reembolso:
        </p>

        <p>
          a) Pedido de cancelamento com mais de 60 (sessenta) dias antes da data de entrega: a
          Contratante fará jus ao reembolso de 100% (cem por cento) do valor total do Contrato;
        </p>

        <p>
          b) Pedido de cancelamento entre 45 (quarenta e cinco) e 60 (sessenta) dias antes da data
          de entrega: a Contratante fará jus ao reembolso de 50% (cinquenta por cento) do valor
          total do Contrato;
        </p>

        <p>
          c) Pedido de cancelamento entre 30 (trinta) e 45 (quarenta e cinco) dias antes da data de
          entrega: a Contratante fará jus ao reembolso de 30% (trinta por cento) do valor total do
          Contrato;
        </p>

        <p>
          d) Pedido de cancelamento com 30 (trinta) dias ou menos antes da data de entrega: não
          haverá reembolso.
        </p>

        <p>
          Caso o pedido de cancelamento ocorra antes do pagamento integral dos valores pactuados, a
          Contratante permanecerá obrigada a realizar o pagamento proporcional ao serviço já
          executado, deduzido o valor do reembolso previsto nas alíneas acima.
        </p>
        <p id="8">
          <strong>8. DISPOSIÇÕES GERAIS:</strong>
        </p>

        <p>
          8.1. Do Procedimento para Solicitação de Reagendamento, Conversão em Créditos,
          Cancelamento e/ou Reembolso: O Participante e/ou Contratante deverá solicitar o
          reagendamento, conversão em créditos, cancelamento e/ou reembolso única e exclusivamente
          através do e-mail atendimento@startse.com, de modo que a solicitação realizada por meio de
          outros canais não terá validade;
        </p>

        <p>
          8.2. Dos Pagamentos Duplicados: Caso o Contratante e/ou Participante tenha pagado duas
          vezes um mesmo Curso Presencial, Curso Online Transmitido ao Vivo, Evento ou Workshop e/ou
          Curso Online, a <strong>STARTSE</strong> irá realizar o reembolso do valor duplicado após
          a confirmação no meio de pagamento utilizado;
        </p>

        <p>
          8.3. Do Atraso em Pagamentos: Caso o Participante e/ou Contratante atrase o pagamento à
          <strong> STARTSE</strong>, será cobrada uma multa de 2% (dois por cento) sobre o valor
          devido, além de juros de 1% (um por cento) ao mês, calculados de forma proporcional aos
          dias de atraso. O valor também será ajustado pela inflação, com base no índice IGP-M ou
          outro índice oficial que o substitua, caso o IGP-M não seja mais utilizado.
        </p>

        <p>
          8.4. O Participante e/ou o Contratante declara ter recebido a presente Política pela
          internet, lido e compreendido seu inteiro teor, redigido em linguagem clara e consciente
          dos direitos e condicionantes que limitam o cumprimento do seu objeto, anuindo com todos
          os seus termos.
        </p>
        <p style={{ marginTop: 32 }}>São Paulo, 30 de novembro de 2024.</p>
        <p>
          <strong>STARTSE INFORMACOES E SISTEMAS LTDA.</strong>
        </p>
      </Content>
      <BackToTop />
    </Container>
  );
};

export default General_v3;
